<template>
  <div class="app-container">
    <!--检索开始-->

    <div class="app-top">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-select v-model="queryCondition.status" placeholder="状态">
            <el-option :value="0" label="全部" />
            <el-option :value="1" label="获取成功" />
            <el-option :value="2" label="未获取成功" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            :loading="listLoading"
          >
            查询
          </el-button>

          <el-button
            type="primary"
            @click="changeFlag(true, 2)"
            icon="el-icon-upload"
            :loading="listLoading"
          >
            生成探针-表情
          </el-button>
          <el-button
            type="primary"
            @click="changeFlag(true, 3)"
            icon="el-icon-upload"
            :loading="listLoading"
          >
            生成探针-链接
          </el-button>
          <el-button
            type="default"
            @click="resetData()"
            icon="el-icon-refresh-left"
            :loading="listLoading"
          >
            刷新
          </el-button>
        </el-form-item>
      </el-form>
      <div class="top-checkbox">
        <el-checkbox v-model="checked" @change="changeChecked">
          查询示例展示
        </el-checkbox>
      </div>
    </div>

    <!--检索结束-->

    <example v-if="checked" />

    <!--查询记录开始-->
    <el-table
      v-loading="listLoading"
      :data="list"
      style="width: 100%; "
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.3)"
      border
      fit
      :height="checked ? windowHeight - 370 : windowHeight - 220"
      highlight-current-row
      class="table-list"
    >
      <el-table-column label="任务说明" prop="user" show-overflow-tooltip />
      <el-table-column prop="status" label="状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.status == 0"
            effect="dark"
            type="danger"
            size="mini"
          >
            获取错误
          </el-tag>
          <el-tag
            v-else-if="scope.row.status == 5"
            effect="dark"
            type="warning"
            size="mini"
          >
            探针链接等待被点击
          </el-tag>
          <el-tag v-else effect="dark" type="success" size="mini">
            获取成功
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="探针链接" width="200">
        <template slot-scope="scope" class="operation">
          {{ scope.row.url }}
          <i
            v-if="scope.row.url"
            @click="copyClick(scope.row.url)"
            style="color: #20a0ff; cursor: pointer"
            class="el-icon-document-copy"
          ></i>
        </template>
      </el-table-column>
      <el-table-column prop="ip" label="IP" show-overflow-tooltip />
      <el-table-column prop="time" label="获取时间" show-overflow-tooltip />
      <el-table-column prop="port" label="端口" show-overflow-tooltip />
      <el-table-column
        prop="ua"
        label="浏览器信息"
        show-overflow-tooltip
        width="400"
      />
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="removeById(scope.row.id)"
          >
            隐藏
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--查询记录结束-->

    <!--分页组件开始-->
    <pagination
      :total="total"
      :page-size="limit"
      :currentPage="current"
      background
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    />
    <!--分页组件结束-->

    <el-dialog
      title="提交查询"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <add
        v-on:listenFlag="changeFlag"
        v-on:addDone="addDone"
        :numberState="numberState"
      />
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import example from './components/example'
import add from './components/add'
import { pageApi, delApi } from '@/api/user'
export default {
  name: 'baiduPhone',
  components: { example, add },

  data() {
    // 这里存放数据",
    return {
      // 定义变量和初始值
      list: null,
      // 当前页
      current: 1,
      // 每页记录数
      limit: 20,
      // 总记录数
      total: 0,
      // 条件封装对象
      queryCondition: {
        status: 0
      },
      // 加载
      listLoading: false,
      dialogVisible: false,
      items: {
        1: { type: 'success', label: '获取成功' },
        0: { type: 'warning', label: '处理中' },
        '-1': { type: 'danger', label: '链接不正确或已失效' }
      },
      numberState: 0,
      checked: true //查询示例展示
    }
  },
  // 监听属性 类似于data概念",
  computed: {
    windowHeight() {
      return this.$store.state.windowData.height
    }
  },

  created() {
    if (this.$route.meta && this.$route.meta.dialogType) {
      if (this.$route.meta.dialogType === 'expressiont') {
        this.dialogVisible = true
        this.numberState = 2
      } else if (this.$route.meta.dialogType === 'link') {
        this.dialogVisible = true
        this.numberState = 3
      }
    }

    // 控制示例
    let example = localStorage.getItem('tz_example')
    if (example) {
      if (example == 'true') {
        this.checked = true
      } else {
        this.checked = false
      }
    }

    this.getList()
  },
  mounted() {},
  // 方法集合
  methods: {
    changeChecked(data) {
      localStorage.setItem('tz_example', data)
    },
    // 头部按条件查询
    searchData() {
      this.current = 1
      this.getList()
    },
    // 清空
    resetData() {
      // 表单输入项数据清空
      this.queryCondition = {
        status: 0
      }
      this.current = 1
      // 查询所有讲师数据
      this.getList()
    },
    // 创建具体的方法，调用teacher.js定义的方法
    // 获取列表
    getList() {
      this.listLoading = true
      pageApi(this.current, this.limit, this.queryCondition)
        .then(response => {
          // 请求成功
          // response 接口返回数据
          this.listLoading = false
          this.list = response.items
          this.total = response.all_count
        })
        .catch(err => {
          // 请求失败
          console.log(err)
        })
    },

    // 删除
    removeById(id) {
      this.$confirm('此操作将隐藏该查询记录, 是否继续?', '提示', {
        // 设置 cancel 和 close 为不同的事件
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancalButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(id)
          .then(response => {
            // 提示信息
            this.$message({
              type: 'success',
              message: '隐藏成功!'
            })
            // 刷新列表页面
            this.getList()
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    // 关闭dialog
    handleClose(done) {
      done()
    },
    // 子组件关闭dialog
    changeFlag(flag, num) {
      this.numberState = num
      this.dialogVisible = flag
    },
    // 复制链接
    copyClick(link) {
      const input = document.createElement('input') // 创建一个隐藏input（重要！）
      input.value = link // 赋值
      document.body.appendChild(input)
      input.select() // 选择对象
      if (document.execCommand('copy')) {
        document.execCommand('copy') // 执行浏览器复制命令
        this.$message({ message: '复制成功', type: 'success' })
      }
      document.body.removeChild(input)
    },
    addDone() {
      this.dialogVisible = false
      this.getList()
    },
    // 分页
    currentChange(val) {
      this.current = val
      this.getList()
    },
    sizeChange(val) {
      this.limit = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-top {
  display: flex;
  justify-content: space-between;
  .top-checkbox {
    align-self: center;
  }
}
.demo-form-inline {
  white-space: nowrap;
}
.dashboard {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}
</style>
