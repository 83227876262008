<template>
  <div style="margin-bottom: 15px;">
    <!--查询示例开始-->
    <fieldset style="border-color: #f4f4f5">
      <legend>查询示例</legend>
      <el-table :data="exampleData" border fit style="width: 100%">
        <el-table-column
          label="任务说明"
          prop="tiebaUrl"
          show-overflow-tooltip
        />
        <el-table-column prop="status" label="状态" show-overflow-tooltip>
          <template>
            <el-tag type="success" effect="dark" size="mini">获取成功</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="qq"
          label="探针链接"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column prop="ip" label="IP" show-overflow-tooltip />
        <el-table-column
          prop="createDate"
          label="获取时间"
          show-overflow-tooltip
        />
        <el-table-column prop="uname" label="端口" show-overflow-tooltip />

        <el-table-column
          prop="remark"
          label="浏览器信息"
          show-overflow-tooltip
          width="400"
        />
      </el-table>
    </fieldset>
    <!--查询示例结束-->
  </div>
</template>

<script>
export default {
  name: 'example',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  data() {
    // 这里存放数据",
    return {
      exampleData: [
        {
          nick: '',
          ip: '123.126.52.36',
          tiebaUrl: '查询示例',
          uid: '1551844709',
          qq: 'http://t.cn/EI9eDHz',
          uname: '36712',
          createDate: '2018-10-23 00:43:46',
          remark:
            'Mozilla/4.0 (compatible; MSIE 7.0; Windows NT 6.0; Trident/5.0)'
        }
      ]
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {},
  // 方法集合",
  methods: {}
}
</script>

<style></style>
