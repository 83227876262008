<template>
  <div class="app-container">
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-form-item label="任务详情" prop="user">
        <el-input v-model="form.user" placeholder="输入任务详情"></el-input>
      </el-form-item>

      <el-form-item label="跳转链接" v-if="numberState == 3" prop="url">
        <el-input
          v-model="form.url"
          style="width: calc(100% - 50px)"
        ></el-input>
        <el-checkbox
          style="margin-left: 20px"
          v-model="form.checked"
          @change="checkedChange"
        ></el-checkbox>
      </el-form-item>

      <span>
        <a :href="downUrl" download="探针使用方法" style="color: #4287dc">
          探针使用方法
          <i class="el-icon-download"></i>
        </a>
      </span>
      <el-form-item style="display: flex; justify-content: center">
        <el-button type="primary" :loading="loading" @click="onSubmit('form')">
          立即提交
        </el-button>
        <el-button @click="onClose">关闭</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addApi } from '@/api/user'
import { Message } from 'element-ui'
export default {
  name: 'add',
  // import引入的组件需要注入到对象中才能使用",
  props: ['numberState'],
  components: {},
  data() {
    let isTrueUrl = (rule, value, callback) => {
      let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/
      if (!reg.test(value)) {
        callback(new Error('请输入正确的链接'))
      } else {
        callback()
      }
    }

    // 这里存放数据",
    return {
      downUrl: process.env.VUE_APP_BASE_API + '/api/probeSystem/download',

      loading: false,
      form: {
        user: '',
        url: 'http://',
        checked: false
      },
      rules: {
        user: [{ required: true, message: '请输入任务', trigger: 'blur' }],
        url: [
          { required: true, message: '请输入链接地址', trigger: 'blur' },
          { validator: isTrueUrl, trigger: 'blur' }
        ]
      }
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {},
  mounted() {},
  // 方法集合",
  methods: {
    checkedChange(state) {
      if (state) {
        this.form.url = 'http://www.ithome.com/'
      } else {
        this.form.url = 'http://'
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        this.loading = true
        if (valid) {
          let obj = Object.assign({}, this.form)
          delete obj.checked
          if (this.numberState !== 3) {
            delete obj.url
          }
          addApi(obj)
            .then(res => {
              Message.success('提交成功！')

              this.form.url = 'http://'
              this.form.user = ''
              this.form.checked = false
              this.loading = false
              this.$emit('addDone')
            })
            .catch(err => {
              this.loading = false
            })
        } else {
          this.loading = false
          return false
        }
      })
    },
    onClose() {
      this.$emit('listenFlag', false, this.numberState)
    }
  }
}
</script>

<style>
.red-font {
  color: red;
}
</style>
